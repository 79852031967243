import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { asyncConnect } from 'redux-connect';
import { withRouter } from 'react-router';
import {
  isLoaded as isSuggestedRolesLoaded,
  load as loadSuggestedRoles,
} from 'redux/modules/suggestedRoles2';
import { loadStats } from 'redux/modules/stats';
import { Button, Col, Row } from 'react-bootstrap';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import HomePageSlider from 'components/LandingPage/HomePageSlider/HomePageSlider';
import RoleSkillSelect from 'components/Shared/RoleSkillSelect';
import FreelancerLookingForWork from '../FreelancerLookingForWork/FreelancerLookingForWork';
import styles from './LandingSlideshow.module.scss';

@asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState } }) => {
        const promises = [];

        if (!isSuggestedRolesLoaded(getState())) {
          promises.push(dispatch(loadSuggestedRoles()));
        }

        if (!getState().stats.loadedStats) {
          promises.push(dispatch(loadStats()));
        }

        return Promise.all(promises);
      },
    },
  ],
  (state) => ({
    loadedSuggestedRoles: state.suggestedRoles2.loaded,
    loadingSuggestedRoles: state.suggestedRoles2.loading,
    suggestedRoles: state.suggestedRoles2.suggestedRoles,
    roleEntities: state.entities.roles,
    stats: state.stats,
  }),
  {
    loadStats,
    loadSuggestedRoles,
  }
)
@withRouter
export default class LandingSlideshow extends Component {
  static propTypes = {
    history: PropTypes.object,
    loadingSuggestedRoles: PropTypes.bool,
    loadedSuggestedRoles: PropTypes.bool,
    suggestedRoles: PropTypes.array,
    roleEntities: PropTypes.object,
    stats: PropTypes.object,
    // functions
    loadSuggestedRoles: PropTypes.func,
    loadStats: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      selectedRole: undefined,
    };
  }

  componentDidMount() {
    const {
      loadingSuggestedRoles,
      loadedSuggestedRoles,
      loadSuggestedRoles,
      stats,
      loadStats,
    } = this.props;

    if (!loadingSuggestedRoles && !loadedSuggestedRoles) {
      loadSuggestedRoles();
    }

    if (!stats.loadingStats && !stats.loadedStats) {
      loadStats();
    }
  }

  handleRoleSelected = (value) => {
    this.setState(
      {
        selectedRole: typeof value === 'undefined' ? undefined : value,
      },
      () => {
        window.location.href =
          '/post-a-job' +
          (typeof this.state.selectedRole === 'undefined'
            ? ''
            : `?role=${this.state.selectedRole.name}`);
      }
    );
  };

  getRoles = () =>
    this.props.suggestedRoles.map((roleId) => this.props.roleEntities[roleId]);

  render() {
    const { loadingSuggestedRoles, stats } = this.props;

    const { selectedRole } = this.state;

    const usersCount = (Math.round(stats.count / 10000) * 10000).toLocaleString(
      'en-US',
      { minimumFractionDigits: 0 }
    );

    return (
      <div className={styles.container}>
        <Row>
          <Col md={6}>
            <div className={styles.mainHeader}>
              <h1
                className="lato-bold fs-58 text-purple text-uppercase"
                style={{ maxWidth: '840px' }}
              >
                Hire Diverse Freelancers
              </h1>
            </div>
            <p className="lato-regular fs-22 mb+" style={{ color: '#333' }}>
              Build diverse teams to beat your competition
            </p>
            <div className="visible-xs-block visible-sm-block">
              <HomePageSlider />
            </div>
            <ul className={styles.bulletPoints}>
              <li>
                <SvgIcon icon="tick_circle" size="20" />
                Post your project or job for <strong>free</strong>
              </li>
              <li>
                <SvgIcon icon="tick_circle" size="20" />
                Connect with <strong>skilled specialist</strong> freelancers
              </li>
              <li>
                <SvgIcon icon="tick_circle" size="20" />
                <strong>{usersCount}+</strong> freelancers across creative and
                digital
              </li>
            </ul>
            <div style={{ position: 'relative' }}>
              <div className={styles.roleSkillSelect}>
                <RoleSkillSelect
                  roles={this.getRoles()}
                  loadingRoles={loadingSuggestedRoles}
                  value={selectedRole}
                  placeholder="What type of freelancer do you need?"
                  onValueChange={this.handleRoleSelected}
                  styles={{ control: (base) => ({ ...base, height: '50px' }) }}
                />
              </div>
              <Button
                bsStyle="success"
                href={
                  '/post-a-job' +
                  (typeof selectedRole !== 'undefined'
                    ? `?role=${selectedRole}`
                    : '')
                }
                className={styles.getStartedButton}
              >
                Post Job{' '}
                <SvgIcon
                  icon="chevron-right"
                  size="18"
                  style={{ position: 'relative', top: '3px' }}
                />
              </Button>
            </div>
            <a className={styles.categories} href="/hire-a-freelancer">
              Or browse all freelance services >
            </a>
            <FreelancerLookingForWork />
          </Col>
          <Col className="visible-md-block visible-lg-block" md={6}>
            <HomePageSlider isMobile={false} />
          </Col>
        </Row>
      </div>
    );
  }
}
