import React from 'react';
import styles from './FreelancerLookingForWork.module.scss';
import { Clearfix } from 'react-bootstrap';

export default function () {
  return (
    <div className="mt fs-16">
      <Clearfix>
        <div className={styles.freelancerBox}>
          <b>Freelancers!</b> Looking for work?{' '}
          <a href="/freelancers">Learn more</a>
        </div>
      </Clearfix>
    </div>
  );
}
