import PropTypes from 'prop-types';
import React from 'react';

import styles from './HomePageSlider.module.scss';
import imagineif1 from './imagineif3.png';
import imagineif2 from './imagineif2.gif';
import imagineif3 from './imagineif1-2.gif';
import imagineifAvatar from './imagineif-avatar.jpg';
import dashikis1 from './dashikis-1.jpg';
import dashikis2 from './dashikis-2.jpg';
import dashikis3 from './dashikis-3.jpg';
import dashikisAvatar from './dashikis-avatar.jpg';
// import gamesessions1 from './gamesessions-1.png';
// import gamesessions2 from './gamesessions-2.png';
// import gamesessions3 from './gamesessions-3.png';
// import gamesessionsAvatar from './gamesessions-avatar.jpg';
import jbcole1 from './jbcole-1.jpg';
import jbcole2 from './jbcole-2.png';
import jbcole3 from './jbcole-3.jpg';
import jbcoleAvatar from './jbcole-avatar.jpg';
import storymodels1 from './storymodels-1.jpg';
import storymodels2 from './storymodels-2.jpg';
import storymodels3 from './storymodels-3.jpg';
import storymodelsAvatar from './storymodels-avatar.jpg';

class HomePageSlider extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    isMobile: true,
  };

  constructor(props) {
    super(props);

    const data = [
      {
        logos: [
          {
            image: jbcole1,
            backgroundColor: '#eee',
          },
          {
            image: jbcole2,
            backgroundColor: '#fff',
          },
          {
            image: jbcole3,
            backgroundColor: '#000',
          },
        ],
        quote: 'Excellent quality programmers. Helped deliver for our client!',
        avatar: jbcoleAvatar,
      },
      {
        logos: [
          {
            image: dashikis1,
          },
          {
            image: dashikis2,
          },
          {
            image: dashikis3,
            backgroundColor: '#e2ffc9',
          },
        ],
        quote: 'A great quality logo helped me raise even more money',
        avatar: dashikisAvatar,
      },
      {
        logos: [
          {
            image: storymodels1,
            backgroundColor: '#fdf8ee',
          },
          {
            image: storymodels2,
            backgroundColor: '#fdf8ee',
          },
          {
            image: storymodels3,
            backgroundColor: '#edf8f7',
          },
        ],
        quote: 'The service is pivotal to any SME looking to hire creatives',
        avatar: storymodelsAvatar,
      },
      // {
      //   logos: [{
      //     image: gamesessions1,
      //     backgroundColor: '#fff',
      //   }, {
      //     image: gamesessions2,
      //     backgroundColor: '#f0d6d4',
      //   }, {
      //     image: gamesessions3,
      //     backgroundColor: '#0c1120',
      //   }],
      //   quote: 'The redesign has taken our brand to the next level',
      //   avatar: gamesessionsAvatar,
      // },
    ];

    // If on desktop, add the animation imagineif
    if (!props.isMobile) {
      data.splice(0, 0, {
        logos: [
          {
            image: imagineif3,
            backgroundColor: '#ffffff',
          },
          {
            image: imagineif1,
            backgroundColor: '#bbeef0',
          },
          {
            image: imagineif2,
            backgroundColor: '#ffffff',
          },
        ],
        quote: 'Great experience. I found Marie & loved her animations.',
        avatar: imagineifAvatar,
      });
    }

    this.state = {
      currentSlide: 0,
      data,
      sliderTimer: null,
    };

    this.incrementSlide = this.incrementSlide.bind(this);
  }

  componentDidMount() {
    this.updateSlider();
  }

  componentWillUnmount() {
    this.updateSlider(false);
  }

  incrementSlide() {
    this.setState({
      currentSlide: ++this.state.currentSlide % this.state.data.length,
    });
  }

  updateSlider(restart = true, startAt = 0) {
    if (this.state.sliderTimer) {
      clearInterval(this.state.sliderTimer);
    }

    if (restart) {
      this.setState({
        currentSlide: startAt,
        sliderTimer: setInterval(this.incrementSlide, 4000),
      });
    }
  }

  render() {
    const { isMobile, ...rest } = this.props;

    const { currentSlide, data } = this.state;

    return (
      <div className={styles.container}>
        {data.map((data, idx) => (
          <div
            className={`${styles.slide} ${
              currentSlide === idx ? styles.showing : ''
            }`}
            key={idx}
          >
            {isMobile ? (
              <div className="visible-xs-block visible-sm-block">
                <div className={styles.logoContainerMobile}>
                  <div
                    className={styles.smallMobileLogoContainer}
                    style={{
                      backgroundColor: data.logos[0].backgroundColor
                        ? data.logos[0].backgroundColor
                        : '#fff',
                    }}
                  >
                    <img src={data.logos[0].image} alt="Twine" />
                  </div>
                  <div
                    className={styles.mediumMobileLogoContainer}
                    style={{
                      backgroundColor: data.logos[1].backgroundColor
                        ? data.logos[1].backgroundColor
                        : '#fff',
                    }}
                  >
                    <img src={data.logos[1].image} alt="Twine" />
                  </div>
                  <div
                    className={styles.largeMobileLogoContainer}
                    style={{
                      backgroundColor: data.logos[2].backgroundColor
                        ? data.logos[2].backgroundColor
                        : '#fff',
                    }}
                  >
                    <img src={data.logos[2].image} alt="Twine" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="visible-md-block visible-lg-block">
                <div className={styles.logoContainer}>
                  <div
                    className={`${styles.smallLogoContainer} ${styles.smallLogoContainerTop}`}
                    style={{
                      backgroundColor: data.logos[0].backgroundColor
                        ? data.logos[0].backgroundColor
                        : '#fff',
                    }}
                  >
                    <img src={data.logos[0].image} alt="Twine" />
                  </div>
                  <div
                    className={`${styles.smallLogoContainer} ${styles.smallLogoContainerBottom}`}
                    style={{
                      backgroundColor: data.logos[1].backgroundColor
                        ? data.logos[1].backgroundColor
                        : '#fff',
                    }}
                  >
                    <img src={data.logos[1].image} alt="Twine" />
                  </div>
                  <div
                    className={styles.largeLogoContainer}
                    style={{
                      backgroundColor: data.logos[2].backgroundColor
                        ? data.logos[2].backgroundColor
                        : '#fff',
                    }}
                  >
                    <img src={data.logos[2].image} alt="Twine" />
                  </div>
                </div>
                <div className={styles.userContainer}>
                  <div className={styles.quote}>&ldquo;{data.quote}&rdquo;</div>
                  <img src={data.avatar} alt="Twine" />
                </div>
              </div>
            )}
          </div>
        ))}

        <div className={styles.counter}>
          {data.map((data, idx) => (
            <div
              className={currentSlide === idx ? styles.currentSlide : ''}
              key={idx}
              onClick={() => this.updateSlider(true, idx)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default HomePageSlider;
