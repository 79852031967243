import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Clearfix, Col, Grid } from 'react-bootstrap';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './LandingStillNotReady.module.scss';

export default class LandingStillNotReady extends Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    title: 'Still unsure if you’re ready to hire?',
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { title, className, gradient, ...rest } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            {title ? (
              <h2 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
                {title}
              </h2>
            ) : null}
            <Grid className={styles.sections}>
              <Col
                className={styles.section + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
                md={4}
              >
                <p>
                  <a href="https://www.twine.net/blog/hiring-your-friends/">
                    What's wrong with just hiring my friends?
                  </a>
                </p>
                <p>
                  Hiring your friends sounds tempting as it's cheap and someone
                  you trust. It's not as cushy as it first appears though. Why?
                  Let's find out.
                </p>
                <p>
                  <a href="https://www.twine.net/blog/hiring-your-friends/">
                    Read more >
                  </a>
                </p>
              </Col>
              <Col
                className={styles.section + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
                md={4}
              >
                <p>
                  <a href="https://www.twine.net/blog/avoid-low-quality-content/">
                    Is it ever worth going cheap?
                  </a>
                </p>
                <p>
                  The idea of cheap freelance work is very tempting, but is it
                  worth the risk? What are you really getting for your money?
                  Let's investigate.
                </p>
                <p>
                  <a href="https://www.twine.net/blog/avoid-low-quality-content/">
                    Read more >
                  </a>
                </p>
              </Col>
              <Clearfix visibleSmBlock />
              <Col
                className={styles.section + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
                md={4}
              >
                <p>
                  <a href="https://www.twine.net/blog/see-big-picture-plan-content-effectively/">
                    How do I work out what content I need?
                  </a>
                </p>
                <p>
                  Do you need content for your business but are not sure exactly
                  what content you want or how to get it? Don't worry this
                  article will help you plan it out.
                </p>
                <p>
                  <a href="https://www.twine.net/blog/see-big-picture-plan-content-effectively/">
                    Read more >
                  </a>
                </p>
              </Col>
              <Clearfix visibleMdBlock visibleLgBlock />
              <Col
                className={styles.section + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
                md={4}
              >
                <p>
                  <a href="https://www.twine.net/blog/top-quality-freelancers/">
                    How can I be sure that Twine's freelancers are good quality?
                  </a>
                </p>
                <p>
                  We know how difficult it is to find top quality creatives
                  which is why we built Twine. Read this article to find how we
                  can guarantee you quality.
                </p>
                <p>
                  <a href="https://www.twine.net/blog/top-quality-freelancers/">
                    Read more >
                  </a>
                </p>
              </Col>
              <Clearfix visibleSmBlock />
              <Col
                className={styles.section + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
                md={4}
              >
                <p>
                  <a href="https://www.twine.net/blog/essential-tools-businesses/">
                    12 essential tools to run your business
                  </a>
                </p>
                <p>
                  There are so much to keep on top of when running a business:
                  invoicing, accounting, insurance, etc. Thankfully, there’s a
                  lot of amazing tools to help.
                </p>
                <p>
                  <a href="https://www.twine.net/blog/essential-tools-businesses/">
                    Read more >
                  </a>
                </p>
              </Col>
              <Col
                className={styles.section + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
                md={4}
              >
                <p>
                  <a href="https://www.twine.net/blog/problems-with-creative-freelancers/">
                    Twine vs Freelancers
                  </a>
                </p>
                <p>
                  Hiring freelancers you don't know is a scary prospect. With
                  Twine, you're not left floundering around in the dark. See how
                  we compare to other freelancer sites.
                </p>
                <p>
                  <a href="https://www.twine.net/blog/problems-with-creative-freelancers/">
                    Read more >
                  </a>
                </p>
              </Col>
            </Grid>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
