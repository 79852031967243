import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LandingTrustTwine from 'components/LandingPage/LandingTrustTwine/LandingTrustTwine';
import LandingRatings from 'components/LandingPage/LandingRatings/LandingRatings';
import LandingPressSection from 'components/LandingPage/LandingPressSection/LandingPressSection';
import LandingGetStarted from 'components/LandingPage/LandingGetStarted/LandingGetStarted';
import LandingFindUs from 'components/LandingPage/LandingFindUs/LandingFindUs';
import LandingWhyUseTwine from 'components/LandingPage/LandingWhyUseTwine/LandingWhyUseTwine';
import LandingWhyUseTwinePortfolio from 'components/LandingPage/LandingWhyUseTwinePortfolio/LandingWhyUseTwinePortfolio';
import LandingSlideshow from 'components/LandingPage/LandingSlideshow/LandingSlideshow';
import LandingHowItWorks from 'components/LandingPage/LandingHowItWorks/LandingHowItWorks';
import LandingStillNotReady from 'components/LandingPage/LandingStillNotReady/LandingStillNotReady';

export default class Home extends Component {
  static propTypes = {
    stats: PropTypes.object,
    // functions
    loadStats: PropTypes.func,
  };

  componentDidMount() {
    if (!this.props.stats.loadingStats && !this.props.stats.loadedStats) {
      this.props.loadStats();
    }
  }

  render() {
    const { stats } = this.props;

    const usersCount = (Math.round(stats.count / 10000) * 10000).toLocaleString(
      'en-US',
      { minimumFractionDigits: 0 }
    );

    return (
      <div>
        <LandingSlideshow />
        <LandingFindUs style={{ paddingBottom: '100px' }} />
        <LandingWhyUseTwine
          sections={[1, 3]}
          gradient
          seeMore
          usersCount={usersCount}
        />
        <LandingWhyUseTwinePortfolio />
        <LandingHowItWorks gradient style={{ paddingBottom: '50px' }} />
        <LandingTrustTwine
          title="Customer stories"
          gradient="left"
          style={{ paddingBottom: '100px' }}
        />
        <LandingRatings total={0} style={{ paddingBottom: '100px' }} />
        {/* <LandingAskTwineSection background="grey" /> */}
        <LandingStillNotReady gradient style={{ paddingBottom: '80px' }} />
        <LandingGetStarted
          title="Bring your project to life today"
          gradient="left"
          style={{ background: '#322954' }}
        />
        <LandingPressSection />
      </div>
    );
  }
}
