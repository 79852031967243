import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import VisibilitySensor from 'react-visibility-sensor';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import styles from './LandingRatings.module.scss';
import starRating from './star_rating.png';

export default class LandingRatings extends Component {
  static propTypes = {
    average: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.number,
    role: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    average: 4.6,
    total: 540,
    role: 'Freelancers',
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { average, total, role, className, ...rest } = this.props;

    const { visible } = this.state;

    const event = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={styles.container + (className ? ' ' + className : '')}
          {...rest}
        >
          <Grid>
            <Row className={styles.ratingContainer}>
              <Col
                className={styles.stars + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
              >
                <LazyLoad>
                  <img src={starRating} alt="Twine" />
                </LazyLoad>
              </Col>
              <Col
                className={styles.text + (visible ? '' : ' ' + styles.hide)}
                xs={12}
                sm={6}
              >
                {total ? (
                  <div itemScope itemType="https://schema.org/Product">
                    <div
                      itemProp="aggregateRating"
                      itemScope
                      itemType="https://schema.org/AggregateRating"
                    >
                      <p>
                        Clients Rate Twine {role}{' '}
                        <span itemProp="ratingValue">{average}</span> / 5.0
                      </p>
                      <p className={styles.total}>
                        on average across{' '}
                        <span itemProp="ratingCount">{total}</span>
                        <span itemProp="reviewCount" content={total} /> reviews
                        as of {event.toLocaleDateString('en-US', options)}
                      </p>
                    </div>
                    <span itemProp="brand" content="Twine" />
                    <span
                      itemProp="name"
                      content={`${role} Freelance Services`}
                    />
                    <span
                      itemProp="description"
                      content={`Hire the best ${role.toLowerCase()} freelancers with Twine.`}
                    />
                  </div>
                ) : (
                  <div>
                    <p>Clients rate Twine freelancers 4.6 / 5.0</p>
                    <p className={styles.total}>
                      on average across 540 reviews as of{' '}
                      {event.toLocaleDateString('en-US', options)}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
      </VisibilitySensor>
    );
  }
}
