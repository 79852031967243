import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Slider from 'react-slick';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './TestimonialsSlider.module.scss';
import testimonialBubble from '../../../static/images/landing-page/testimonials-background.png';

export default class TestimonialsSlider extends Component {
  static propTypes = {
    title: PropTypes.string,
    hideSpeachBubble: PropTypes.bool,
    changeSpeed: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    title: 'What our customers say',
    hideSpeachBubble: false,
    changeSpeed: 4000,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible) {
      setTimeout(() => {
        this.next();
      }, this.props.changeSpeed);
    }
  }

  getTestimonials() {
    return [
      {
        testimonial: 'The redesign has taken our brand to the next level',
        user: 'Ed, CEO of Game Sessions',
      },
      {
        testimonial:
          'The service is pivotal to any SME looking to hire creatives',
        user: 'Greg, Director of Story Models Modeling Agency',
      },
      {
        testimonial: 'A great quality logo helped me raise even more money',
        user: 'Christine, Director of Vazii - Dashikis for a cause',
      },
      {
        testimonial: "I can't believe how good quality the logo was!",
        user: 'Nik, Owner of Star Gladiator Personal Training',
      },
      {
        testimonial: "I'd definitely recommend Twine. Great service!",
        user: 'Anthony, CEO of BYMYGO',
      },
    ];
  }

  next() {
    this.slider.slickNext();

    setTimeout(() => {
      this.next();
    }, this.props.changeSpeed);
  }

  render() {
    const { title, hideSpeachBubble, className, changeSpeed, ...rest } =
      this.props;

    const { visible } = this.state;

    const testimonials = this.getTestimonials();

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={styles.container + (className ? ' ' + className : '')}
          {...rest}
        >
          {title ? (
            <h5 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
              {title}
            </h5>
          ) : null}
          <div
            className={styles.testimonials + (visible ? '' : ' ' + styles.hide)}
          >
            <Slider
              ref={(slider) => (this.slider = slider)}
              dots
              arrows={false}
            >
              {testimonials.map((testimonial, key) => {
                return (
                  <div className={styles.testimonial} key={key}>
                    {!hideSpeachBubble && (
                      <img
                        alt="Twine"
                        className={styles.testimonialImage}
                        src={testimonialBubble}
                      />
                    )}
                    <div className={styles.testimonialText}>
                      <p className={styles.testimonialTitle}>
                        {testimonial.testimonial}
                      </p>
                      <p className={styles.testimonialUser}>
                        {testimonial.user}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
