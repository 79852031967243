import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import TopCategories from 'components/Categories/TopCategories/TopCategories';
import styles from './LandingFindUs.module.scss';

export default class LandingFindUs extends Component {
  static propTypes = {
    className: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { className, gradient, ...rest } = this.props;

    const { visible } = this.state;
    const classes = [
      gradient
        ? (styles.gradient,
          gradient === 'left' ? styles.gradientLeft : styles.gradientRight)
        : styles.noGradient,
      className && className,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div className={classes} {...rest}>
          <div className={styles.container}>
            <Link to="/hire-a-freelancer" title="Hire a freelancer">
              <h3 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
                Hire vetted freelancers
              </h3>
            </Link>
            <p className={styles.subtitle + (visible ? '' : ' ' + styles.hide)}>
              We will match you with vetted freelancers from our global network
              of half a million. Here's some of the 200 creative and digital
              services our freelancers provide
            </p>
            <TopCategories design="illustration" />
            <Link to="/hire-a-freelancer" title="Hire a freelancer">
              <Button
                className={styles.seeMore + (visible ? '' : ` ${styles.hide}`)}
                bsSize="lg"
              >
                Browse more categories >
              </Button>
            </Link>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
