import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import VisibilitySensor from 'react-visibility-sensor';
import TestimonialsSlider from 'components/TestimonialsSlider/TestimonialsSlider';
import cocacola from 'components/LandingPage/LandingClientsSection/cocacola.png';
import disney from 'components/LandingPage/LandingClientsSection/disney.png';
import mtv from 'components/LandingPage/LandingClientsSection/mtv.png';
import muppets from 'components/LandingPage/LandingClientsSection/muppets.png';
import nokia from 'components/LandingPage/LandingClientsSection/nokia.png';
import starWars from 'components/LandingPage/LandingClientsSection/star-wars.png';
import styles from './LandingTrustTwine.module.scss';

export default class LandingTrustTwine extends Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    title: 'Trust Twine',
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { title, gradient, className, ...rest } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            <h3 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
              {title}
            </h3>
            <Grid>
              <Row>
                <Col className={styles.infoContainer} xs={12} sm={6}>
                  <div className={styles.infoList}>
                    <p className={visible ? '' : styles.hide}>
                      Our freelancers have worked with
                    </p>
                    <img
                      className={visible ? '' : styles.hide}
                      src={disney}
                      alt="Twine"
                    />
                    <img
                      className={visible ? '' : styles.hide}
                      src={cocacola}
                      alt="Twine"
                    />
                    <img
                      className={visible ? '' : styles.hide}
                      src={mtv}
                      alt="Twine"
                    />
                    <img
                      className={visible ? '' : styles.hide}
                      src={muppets}
                      alt="Twine"
                    />
                    <img
                      className={visible ? '' : styles.hide}
                      src={nokia}
                      alt="Twine"
                    />
                    <img
                      className={visible ? '' : styles.hide}
                      src={starWars}
                      alt="Twine"
                    />
                  </div>
                </Col>
                <Col
                  className={
                    styles.testimonialsContainer +
                    (visible ? '' : ' ' + styles.hide)
                  }
                  xs={12}
                  sm={6}
                >
                  <TestimonialsSlider />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
