import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import devices from 'containers/LandingPages/Enterprise/devices.png';
import phone from 'containers/LandingPages/Enterprise/phone.png';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import styles from './LandingWhyUseTwinePortfolio.module.scss';

export default class LandingWhyUseTwinePortfolio extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    seeMore: PropTypes.string,
    seeMoreText: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    title: 'AI Companies looking for diverse datasets.',
    subtitle:
      'Twine’s global network of freelancers can build large audio and video datasets to help reduce machine learning bias.',
    seeMore: '/ai-data',
    seeMoreText: 'Learn more >',
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const {
      className,
      title,
      subtitle,
      seeMore,
      seeMoreText,
      gradient,
      ...rest
    } = this.props;
    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 50}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            <Grid>
              <Row>
                <Col
                  className={
                    styles.infoContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={12}
                  sm={6}
                  smPush={6}
                >
                  <h2>{title}</h2>
                  <p>{subtitle}</p>
                  <p>
                    <a href={seeMore}>{seeMoreText}</a>
                  </p>
                </Col>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={12}
                  sm={6}
                  smPull={6}
                >
                  <LazyLoad>
                    <img
                      alt="Twine"
                      className="hidden-xs hidden-lg"
                      src={phone}
                    />
                  </LazyLoad>
                  <LazyLoad>
                    <img
                      alt="Twine"
                      className="hidden-sm hidden-md"
                      src={devices}
                    />
                  </LazyLoad>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
