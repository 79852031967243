import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Select from 'react-select';

class RoleSkillSelect extends PureComponent {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.object),
    skills: PropTypes.arrayOf(PropTypes.object),
    highlightRoles: PropTypes.arrayOf(PropTypes.string),
    excludeRoles: PropTypes.arrayOf(PropTypes.string),
    excludeSkills: PropTypes.arrayOf(PropTypes.string),
    loadingRoles: PropTypes.bool,
    loadingSkills: PropTypes.bool,
    value: PropTypes.object,
    onValueChange: PropTypes.func,
    styles: PropTypes.object,
  };

  state = {
    search: '',
  };

  getRoles = () =>
    this.props.roles.map((role) => {
      role.type = 'role';
      return role;
    });

  getSkills = () =>
    this.props.skills.map((skill) => {
      skill.type = 'skill';
      return skill;
    });

  getRoleSkills = () => {
    const roles = this.filterRoles(this.getRoles());
    const skills = this.filterSkills(this.getSkills());

    const roleSkills = roles.concat(skills);

    return roleSkills.sort((first, second) => first.name - second.name);
  };

  filterRoles = (roles) =>
    roles.filter(
      (role) => this.props.excludeRoles.indexOf(role.name.toLowerCase()) === -1
    );

  filterSkills = (skills) =>
    skills.filter(
      (skill) =>
        this.props.excludeRoles.indexOf(skill.name.toLowerCase()) === -1
    );

  render() {
    const roleSkills = this.getRoleSkills();
    const {
      loadingRoles,
      loadingSkills,
      value,
      onValueChange,
      highlightRoles,
    } = this.props;

    let options = roleSkills.map((roleSkill) => ({
      label: roleSkill.name,
      value: roleSkill,
    }));

    if (this.state.search === '' && highlightRoles.length > 0) {
      const highlights = highlightRoles.map((roleSkill) =>
        roleSkill.toLowerCase()
      );

      options = options.filter(
        (roleSkill) => highlights.indexOf(roleSkill.label.toLowerCase()) > -1
      );
    }

    return (
      <Select
        {...this.props}
        isSearchable
        options={options}
        value={
          value
            ? value.selectedRole
              ? { label: value.selectedRole.name, value }
              : { label: value.name.value }
            : undefined
        }
        noOptionsMessage={() => (
          <div className="no-results-found">
            {loadingRoles || loadingSkills
              ? 'loading roles...'
              : 'No roles found'}
          </div>
        )}
        onInputChange={(search) => this.setState({ search })}
        onChange={(selected) =>
          typeof selected === 'undefined'
            ? onValueChange(undefined, undefined)
            : onValueChange(selected.value, selected.value.type)
        }
      />
    );
  }
}

RoleSkillSelect.defaultProps = {
  roles: [],
  skills: [],
  highlightRoles: [],
  excludeRoles: [
    'agent',
    'film agent',
    'music agent',
    'promoter',
    'record label',
    'artist manager',
    'location manager',
  ],
  excludeSkills: [],
  loadingRoles: false,
  loadingSkills: false,
};

export default RoleSkillSelect;
